import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { bool, object, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as SocialIcons from '../../atoms/icons/social'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'
import { SocialWrapper, Wrapper } from './social-links.styled'

const SocialLinks = ({ showTitle, titleProps, className, buttonProps, iconProps }) => {
	const { social } = useConfig()
	return (
		<>
			{social?.length > 0 && (
				<Wrapper className={cx('SocialLinks-root', className)}>
					{showTitle && (
						<Typography component="p" {...titleProps}>
							<FormattedMessage id="footer_social_title" />
						</Typography>
					)}
					<SocialWrapper className="BHSocialWrapper">
						{social.map(({ type: name, link }, index) => {
							// eslint-disable-next-line import/namespace
							if (!SocialIcons[`${name}Icon`]) {
								console.error(`${name}Icon is not found`)
								return null
							}
							// eslint-disable-next-line import/namespace
							const Icon = SocialIcons[`${name}Icon`]
							return (
								<Button
									className={`icon-${name.toLowerCase()}`}
									Icon={Icon}
									key={index}
									href={link}
									target="_blank"
									label={name}
									{...buttonProps}
									iconProps={iconProps}
								/>
							)
						})}
					</SocialWrapper>
				</Wrapper>
			)}
		</>
	)
}

SocialLinks.propTypes = {
	/**
	 *  True to show Title, false to hidden
	 */
	showTitle: bool,
	/**
	 *  Defines properties of the title
	 */
	titleProps: object,
	/**
	 *  Defines className for root node
	 */
	className: string,
	/**
	 *  Defines properties of single Button
	 */
	buttonProps: object,
	/**
	 *  Defines properties of single Icon
	 */
	iconProps: object,
}

export default SocialLinks
