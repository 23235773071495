import { useStoreLanguages } from '@bluheadless/ui-logic/src/hooks/store-languages/useStoreLanguages'
import { useWorldStores } from '@bluheadless/ui-logic/src/hooks/world-stores/useWorldStores'
import { useCurrency } from '@bluheadless/ui-logic/src/providers/currency'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { useThemeProps } from '@mui/system'
import { bool, func, string } from 'prop-types'
import FlagByCountryCode from '../../atoms/icon-flag-by-country-code'
import ArrowheadDownIcon from '../../atoms/icons/arrowhead-down-icon'
import {
	CountryCode,
	CurrencySymbol,
	Language,
	Separator,
	TriggerWrapper,
} from './change-country-language-trigger.styled'

const ChangeCountryLanguageTrigger = ({ className, ...inProps }) => {
	const props = useThemeProps({
		name: 'BHChangeCountryLanguageTrigger',
		props: inProps,
	})

	const {
		countryCode,
		customSymbol: customSymbolProp,
		loading: _loading,
		onClick,
		showArrowDown,
		showCountryCode: _showCountryCode,
		showCurrencySymbol: _showCurrencySymbol,
		showFlag: _showFlag,
		showLanguage: _showLanguage,
		showSeparator,
		showCountryName,
		customLabel = null,
	} = props

	if (!_showFlag && !_showCountryCode && !_showCurrencySymbol && !_showLanguage) {
		console.warn(
			'[ChangeCountryLanguageTrigger] One of showFlag, showCountryCode, showCurrencySymbol or showLanguage should be true'
		)
	}

	const { symbol } = useCurrency()

	const currencySymbol = customSymbolProp || symbol

	const { code: currentStoreCode, name: currentLanguage } = useStore()
	const { languages, loading: loadingLanguages } = useStoreLanguages({ store: currentStoreCode })
	const { isSingleWebsite, countries } = useWorldStores()
	const isSingleLanguage = languages?.length === 1
	/**
	 * available combos:
	 * flag + country code (+ currency symbol)
	 * flag + language (+ currency symbol)
	 * country code + language (+ currency symbol)
	 */
	const showFlag = _showFlag && !(_showCountryCode && _showLanguage)
	const showCountryCode = _showCountryCode && !(_showFlag && _showLanguage)
	const showLanguage = _showLanguage && !(_showFlag && _showCountryCode)
	const showCurrencySymbol = _showCurrencySymbol && (showFlag || showCountryCode || showLanguage)

	const loading = _loading || loadingLanguages

	// we should not open the dropdown if there's only one website with only one language.
	const disableTrigger = loading || (isSingleWebsite && isSingleLanguage)

	const countryName = countries.find((country) => country?.code == countryCode)?.name

	return showFlag || showCountryCode || showLanguage || showCountryName ? (
		<TriggerWrapper className={className} onClick={!disableTrigger ? onClick : undefined}>
			{showFlag && <FlagByCountryCode countryCode={loading ? undefined : countryCode} />}
			{customLabel && <>{customLabel}&nbsp;</>}
			{showSeparator && showFlag && (showCurrencySymbol || showCountryCode) && <Separator>|</Separator>}
			{showCountryCode && (
				<CountryCode addMargin={!showSeparator && showFlag}>{loading ? '--' : countryCode}</CountryCode>
			)}
			{showCountryName && <>{countryName}</>}
			{showLanguage && (
				<Language addMargin variant="body1" component="span">
					<>&nbsp;/&nbsp;</>
					{currentLanguage}
				</Language>
			)}
			{showCurrencySymbol && currencySymbol && (
				<CurrencySymbol>
					{(showFlag || showCountryCode) && <>&nbsp;/&nbsp;&nbsp;</>}
					{currencySymbol}
				</CurrencySymbol>
			)}
			{showArrowDown && !disableTrigger && <ArrowheadDownIcon fontSize="small" />}
		</TriggerWrapper>
	) : null
}

ChangeCountryLanguageTrigger.propTypes = {
	countryCode: string,
	customSymbol: string,
	showArrowDown: bool,
	showSeparator: bool,
	showFlag: bool,
	showCountryCode: bool,
	showCurrencySymbol: bool,
	showLanguage: bool,
	onClick: func,
}

export default ChangeCountryLanguageTrigger
