import Form from '@bluheadless/ui-logic/src/components/form/form'
import MUIGrid from '@mui/material/Grid'
import { styled } from '@mui/system'
import Typography from '../../atoms/typography'

const shouldForwardProp = (prop) => !['variant'].includes(prop)

const FormStyled = styled(Form, { shouldForwardProp })`
	width: 100%;
	max-width: 720px;
`

const Title = styled(Typography)`
	margin-bottom: var(--size-10);
	font-size: ${({ theme }) => theme.typography.pxToRem(32)};
	line-height: 40px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: var(--size-16);
		line-height: 40px;
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

const Subtitle = styled(Typography)`
	margin-bottom: var(--size-16);
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	line-height: var(--size-20);
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 44px;
	}
`

const Wrapper = styled(MUIGrid, { shouldForwardProp })`
	margin: 0 auto;
	${({ variant }) => (variant === 'standard' ? 'flex-wrap: nowrap' : null)};
	${({ variant }) =>
		variant === 'standard' ? 'justify-content: space-around;' : 'flex-wrap: nowrap; flex-direction: column'};
	align-items: flex-start;

	label[for='telephone'] {
		top: -5px;
	}
`

const TitleWrapper = styled('div', { shouldForwardProp })`
	max-width: 420px;
	${({ variant }) => (variant === 'standard' ? 'margin-right: 50px;' : null)};
`

export { FormStyled, Title, Subtitle, Wrapper, TitleWrapper }
