import { Alert as MuiAlert } from '@mui/material'
import { styled } from '@mui/system'

const AlertStyled = styled(MuiAlert)`
	color: ${({ theme }) => theme.palette.white.main};
	${({ severity, theme }) =>
		severity === 'info'
			? `
			background-color: ${theme.palette.primary.main};
			color: ${theme.palette.white.main};
			`
			: ''}
	${({ title }) =>
		title
			? `
			justify-content: flex-start;
			& .MuiAlert-action {
				margin-left: auto;
			}
		`
			: ''}
`

const Message = styled('div')`
	word-break: break-word;
	padding: 3px 0px;
`

export { AlertStyled, Message }
