/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOffIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.76 22.76H1.24V1.24h21.52v21.52zm-20.52-1h19.52V2.24H2.24v19.52z" fill="currentColor" />
	</svg>
))
CheckboxOffIconSvg.displayName = 'CheckboxOffIconSvg'

const CheckboxOffIcon = forwardRef((props, ref) => <Icon component={CheckboxOffIconSvg} ref={ref} {...props} />)
CheckboxOffIcon.displayName = 'CheckboxOffIcon'

CheckboxOffIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOffIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOffIcon
export { CheckboxOffIconSvg }
