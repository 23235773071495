/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MapPinIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 22.59l-.34-.33c-.33-.32-8.16-7.78-8.16-12.36S7.31 1.41 12 1.41s8.5 3.81 8.5 8.5-7.82 12.05-8.16 12.36l-.34.33zm0-20.18c-4.14 0-7.5 3.36-7.5 7.5 0 3.65 5.99 9.8 7.5 11.3 1.51-1.5 7.5-7.66 7.5-11.3 0-4.14-3.36-7.5-7.5-7.5z" />
		<path d="M12 10.91c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1z" />
		<path d="M12 11.41c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm0-2c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5z" />
	</svg>
))
MapPinIconSvg.displayName = 'MapPinIconSvg'

const MapPinIcon = forwardRef((props, ref) => <Icon component={MapPinIconSvg} ref={ref} {...props} />)
MapPinIcon.displayName = 'MapPinIcon'

MapPinIcon.defaultProps = {
	...Icon.defaultProps,
}
MapPinIcon.propTypes = {
	...Icon.propTypes,
}

export default MapPinIcon
export { MapPinIconSvg }
